import React, { useEffect, useState, useRef } from "react";
import { getUserDetail, deleteNote } from "../actions/user";
import {createAirdrop } from "../actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DataTable from "react-data-table-component";
import NoImage from "../assets/noImage.png";
import moment from "moment";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";


const UserDetails = () => {
  
  const form = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [limitError, setLimitError] = useState("");
  const [PurchasePerPage, setPurchasePerPage] = useState(10);
  const [PurchasePage, setPurchasePage] = useState(1);
  const [TransferPerPage, setTransferPerPage] = useState(10);
  const [TransferPage, setTransferPage] = useState(1);
  const [ReferralPerPage, setReferralPerPage] = useState(10);
  const [ReferralPage, setReferralPage] = useState(1);
  const [BYOBAmount, setBYOBAmount] = useState(1);
  const [UserID, setUserID] = useState(1);
  const [NotesPerPage, setNotesPerPage] = useState(10);
  const [NotesPage, setNotesPage] = useState(1);
  let { id } = useParams();
  const { message } = useSelector((state) => state.message);
  const ProfileData = useSelector((state) => state.user.profile);
  const [showPdf, setShowPdf] = useState(false);
  const [setPdf, setSelectPdf] = useState("");
  


  const closePdf = () => {
    setShowPdf(false);
  };

  const pdfModal = () => setShowPdf(true);

  const handlePdf = (e, data) => {
    setSelectPdf(data);
    e.preventDefault();
    pdfModal();
  };


    const handleDeleteNote = (e, id,userId) => {
      e.preventDefault();
      console.log("Row Id", id);
      dispatch(deleteNote(id)).then(() => {
        dispatch(
          getUserDetail(
            userId,
            PurchasePerPage,
            PurchasePage,
            TransferPerPage,
            TransferPage,
            ReferralPerPage,
            ReferralPage,
            NotesPerPage,
            NotesPage,
          )
        );
        setData(ProfileData);
        if (message?.status === "success") {
          toast.success(message.msg);
        }
        if (message?.status === "error") {
          toast.success(message.msg);
        }
      });
    };
 
 

  useEffect(() => {
    dispatch(
      getUserDetail(
        id,
        PurchasePerPage,
        PurchasePage,
        TransferPerPage,
        TransferPage,
        ReferralPerPage,
        ReferralPage,
        NotesPerPage,
        NotesPage,
      )
    );
    setData(ProfileData);
  }, [
    PurchasePerPage,
    PurchasePage,
    TransferPerPage,
    TransferPage,
    ReferralPerPage,
    ReferralPage,
    NotesPerPage,
    NotesPage,
    dispatch,
  ]);


  const fixDecimals = (item) => {
    if (item) {
      return item.toFixed(4);
    }
  };

  const onChangeBYOBAmount = (event) => {
    const input = event.target.value;
      setBYOBAmount(input);
    setUserID(id)
  }

  const handleAirDrop = async (e) => {
    e.preventDefault();

    console.log(UserID, BYOBAmount);
        await dispatch(createAirdrop(UserID, BYOBAmount)).then(async () => {
          setUserID("")
          setBYOBAmount("")
        });
        console.log(message)
        if (message && message.status === 'success') {
          toast.success(message.message);
        }
        if (message && message.status === 'error') {
          toast.error(message.message);
        }
       
  };



  const handlePurchasePageChange = (PurchasePage) => {
    dispatch(getUserDetail(id, PurchasePerPage, PurchasePage));
    setPurchasePage(PurchasePage);
  };

  

  const handlePurchasePerRowsChange = async (
    newPurchasePerPage,
    PurchasePage
  ) => {
    setPurchasePerPage(newPurchasePerPage);
  };

  const handleTransferPageChange = (TransferPage) => {
    dispatch(getUserDetail(id, TransferPerPage, TransferPage));
    setTransferPage(TransferPage);
  };

  const handleTransferPerRowsChange = async (
    newTransferPerPage,
    TransferPage
  ) => {
    setTransferPerPage(newTransferPerPage);
  };

  const handleReferralPageChange = (ReferralPage) => {
    dispatch(getUserDetail(id, ReferralPerPage, ReferralPage));
    setReferralPage(ReferralPage);
  };

  const handleReferralPerRowsChange = async (
    newReferralPerPage,
    ReferralPage
  ) => {
    setReferralPerPage(newReferralPerPage);
  };

  const handleNotesPageChange = (NotesPage) => {
    dispatch(getUserDetail(id, NotesPerPage, NotesPage));
    setNotesPage(NotesPage);
  };

  const handleNotesPerRowsChange = async (
    newNotesPerPage,
    NotesPage
  ) => {
    setNotesPerPage(newNotesPerPage);
  };

  const ReferralsColumn = [
    {
      name: "First Name",
      selector: (row) => row.firstName,
      withd: "100px",
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      withd: "100px",
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "300px",
    },
  ];

  const PurchaseColumns = [
    {
      name: "Purchase Date",
      selector: (row) => moment(Number(row.purchaseDate)).format("DD-MMM-YYYY"),
    },
    {
      name: "User Email",
      selector: (row) => row.userId?.email,
      sortable: true,
    },
    {
      name: "Amount USD",
      selector: (row) => "$" + row.amountUSD.toFixed(2),
      sortable: true,
    },
    {
      name: "Amount in BYOB",
      selector: (row) => fixDecimals(row?.byobAmount),
      sortable: true,
    },
    {
      name: "Purchase Type",
      selector: (row) => row.purchaseType,
      sortable: true,
    },
    {
      name: "Stripe ID",
      selector: (row) =>
        row?.stripeId
          ? row?.stripeId?.substring(0, 4) +
            "..." +
            row?.stripeId?.substring(
              row?.stripeId?.length - 4,
              row?.stripeId?.length
            )
          : "...",
      sortable: true,
    },
    {
      name: "Blockchain TxnId",
      //show first and last 4 characters with ...
      selector: (row) =>
        row?.blockchainTxnId
          ? row?.blockchainTxnId?.substring(0, 4) +
            "..." +
            row?.blockchainTxnId?.substring(
              row?.blockchainTxnId?.length - 4,
              row?.blockchainTxnId?.length
            )
          : "...",
      sortable: true,
    },
    {
      name: "Purchase Wallet",
      selector: (row) =>
        row?.purchaseFromWallet
          ? row?.purchaseFromWallet?.slice(0, 6) +
            "...." +
            row?.purchaseFromWallet?.slice(-5)
          : "...",
      sortable: true,
    },
    {
      name: "Transfer Status",
      selector: (row) => row.transferStatus,
      sortable: true,
    },
  ];

  const TransferColumns = [
    {
      name: "Request Date",
      selector: (row) =>
        row.transferRequestDate
          ? moment(Number(row.transferRequestDate)).format("DD-MMM-YYYY")
          : "-",
      sortable: true,
    },
    {
      name: "Transfer Date",
      selector: (row) =>
        row.transferDate
          ? moment(Number(row.transferDate)).format("DD-MMM-YYYY")
          : "-",
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.userId?.email,
      sortable: true,
    },
    {
      name: "Amount in BYOB",
      selector: (row) => fixDecimals(row?.byobAmount),
      sortable: true,
    },

    {
      name: "Transfer Blockchain TxnId",
      selector: (row) =>
        row.transferBlockchainTxId
          ? row.transferBlockchainTxId.substring(0, 4) +
            "..." +
            row.transferBlockchainTxId.substring(
              row.transferBlockchainTxId.length - 4,
              row.transferBlockchainTxId.length
            )
          : "-",
      sortable: true,
    },
    {
      name: "Recepient Wallet",
      selector: (row) =>
        row.transferToWallet
          ? row.transferToWallet.slice(0, 5) +
            "...." +
            row.transferToWallet.slice(-5)
          : "-",
      sortable: true,
    },
    {
      name: "Transfer Status",
      selector: (row) => row.transferStatus,
      sortable: true,
    },
  ];

  const notesColumn = [
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).format("DD-MMM-YYYY HH:MM A"),
      withd: "100px",
    },
    {
      name: "Description",
      selector: (row) =>
        row.description.slice(0, 8) + "...." + row.description.slice(-8),
      withd: "500px",
    },
    {
      name: "File",
      cell: (row) => (
        <div className="img-table">
          {row.image?.split(".").pop() == "pdf" ? (
            <>  
          <button
          class="btn button-lime button-lime-hover btn-xs"
          onClick={(e) => handlePdf(e, row.image)}
        >
        <span>View Pdf file</span>
        </button>
                  </>
                ) : (
                  <img src={row.image || NoImage} alt="" />
                )}
        </div>
        
      ),
    },
     {
      name: "Delete Note ",
      selector: (row) => (
        <button
          className="btn btn-outline btn-xs"
          onClick={(e) => handleDeleteNote(e, row._id,row.userId)}
        >
          <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "100px",
    },
  ];
  return (
    <div className="container rounded bg-white">
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
      <button
        className="float-end mt-2 button-lime text-white border-0 p-2 rounded"
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="row">
        <div className="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Full Name</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">
                    {ProfileData?.data.firstName} {ProfileData?.data.lastName}
                  </p>
                </div>
              </div>
              <hr class="my-2"></hr>
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Email</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">{ProfileData?.data.email}</p>
                </div>
              </div>
              <hr class="my-2"></hr>
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Purchases</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">{ProfileData?.purchaseCount}</p>
                </div>
              </div>
              <hr class="my-2"></hr>
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Purchases in USD</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">
                    $
                    {ProfileData?.purchaseList.reduce(
                      (n, item) => n + item.amountUSD,
                      0
                    )}
                  </p>
                </div>
              </div>
              <hr class="my-2"></hr>
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Token Purchased</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">
                    {fixDecimals(
                      ProfileData?.purchaseList.reduce(
                        (n, item) => n + item.byobAmount,
                        0
                      )
                    )}
                  </p>
                </div>
              </div>
              <hr class="my-2"></hr>
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Tokens Transferred</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">
                    {fixDecimals(
                      ProfileData?.transferList.reduce(
                        (n, item) => n + item.byobAmount,
                        0
                      )
                    )}
                  </p>
                </div>
              </div>
              <hr class="my-2"></hr>
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Tokens Not Transferred</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">
                    {fixDecimals(
                      ProfileData?.purchaseList.reduce(
                        (n, item) => n + item.byobAmount,
                        0
                      ) -
                        ProfileData?.transferList.reduce(
                          (n, item) => n + item.byobAmount,
                          0
                        )
                    )}
                  </p>
                </div>
              </div>
              <hr class="my-2"></hr>
              <div class="row">
                <div class="col-sm-5">
                  <p class="mb-0">Total Transfers</p>
                </div>
                <div class="col-sm-7">
                  <p class="text-muted mb-0">{ProfileData?.tranferCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <h4 className="color-lime text-center">
                  <strong>Airdrop BYOB Token</strong>
                </h4>
                <Form onSubmit={handleAirDrop} ref={form}>
                  <div className="form-group">
                    <Input
                      placeholder="BYOB Amount"
                      type="number"
                      required
                      className="form-control mt-3"
                      name="BYOB Amount"
                      value={BYOBAmount}
                      onChange={onChangeBYOBAmount}
                    />
                  </div>

                  <div className="form-group mt-3 text-center ">
                    <button className="btn button-lime button-lime-hover btn-block btn-lg text-white">
                      <span>Airdrop</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>Purchases</Tab>
          <Tab>Token Transfers</Tab>
          <Tab>My Referrals</Tab>
          <Tab>Notes</Tab>
        </TabList>

        <TabPanel>
          <DataTable
            title="Purchases"
            columns={PurchaseColumns}
            data={ProfileData?.purchaseList}
            pagination
            paginationServer
            paginationTotalRows={ProfileData?.purchaseTotalRows}
            onChangePage={handlePurchasePageChange}
            onChangeRowsPerPage={handlePurchasePerRowsChange}
          />
        </TabPanel>
        <TabPanel>
          <DataTable
            title="Token Transfer"
            columns={TransferColumns}
            data={ProfileData?.transferList}
            pagination
            paginationServer
            paginationTotalRows={ProfileData?.transferTotalRows}
            onChangePage={handleTransferPageChange}
            onChangeRowsPerPage={handleTransferPerRowsChange}
          />
        </TabPanel>
        <TabPanel>
          <DataTable
            title="My Referrals"
            columns={ReferralsColumn}
            data={ProfileData?.data.referralUsedBy}
            pagination
            paginationServer
            paginationTotalRows={ProfileData?.referralTotalRows}
            onChangePage={handleReferralPageChange}
            onChangeRowsPerPage={handleReferralPerRowsChange}
          />
        </TabPanel>
        <TabPanel>
              <DataTable
                title="Notes"
                columns={notesColumn}
                data={ProfileData?.notesList}
                pagination
                paginationServer
                paginationTotalRows={ProfileData?.notesTotalRows}
                onChangePage={handleNotesPageChange}
                onChangeRowsPerPage={handleNotesPerRowsChange}
              />
            </TabPanel>
      </Tabs>
      <Modal  dialogClassName="modal-xl modal-fullscreen-xl-down"show={showPdf} onHide={closePdf} centered animation={true}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        
                  <div>
                    <object data={setPdf} width="100%"  style={{ height: 'calc(100vh - 43px)' }} />
                  </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserDetails;
