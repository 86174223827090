import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import {  faToggleOff, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateAdminStatus, getAllAdmin } from "../actions/admin";
import { ToastContainer, toast } from "react-toastify";

const Administration = () => {
    const { message } = useSelector((state) => state.message);
    const adminList = useSelector((state) => state.admin.data);
    const currentUser = useSelector((state) => state.auth.user);
console.log(adminList,"========================");
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
  
    const columns = [
      {
        name: "Email",
        selector: (row) => row.email,
        width: "200px",
        sortable: true,
      },
      {
        name: "First Name",
        selector: (row) => row.firstName,
        width: "500px",
        sortable: true,
      },
      {
        name: "Last Name",
        selector: (row) => row.lastName,
        width: "100px",
        sortable: true,
      },
      {
        name: "Verified",
        selector: (row) => row && row.isEmailVerified === true ? ('Yes'):("No"),
        width: "100px",
        sortable: true,
      },
      {
        name: "Status ",
        selector: (row) => 
        row && row.status === false ? ( <div className="d-flex flex-row"><div className="p-1"><p className="m-0 text-muted">Active </p></div><div className="p-1" onClick={(e) => handleAdminStatus(e, row._id)}>
        <FontAwesomeIcon icon={faToggleOn} style={{ color: "red",fontSize:"20px" }} />
      </div><div className="p-1"><p className="m-0">Inactive </p></div></div>
        ) : (
          <div className="d-flex flex-row"><div className="p-1"><p className="m-0">Active </p></div><div className="p-1" onClick={(e) => handleAdminStatus(e, row._id)}>
        <FontAwesomeIcon icon={faToggleOff} style={{ color: "green",fontSize:"20px" }} />
      </div><div className="p-1"><p className="m-0 text-muted">Inactive </p></div></div>
          // <button
          //   className="btn btn-outline btn-xs"
          //   onClick={(e) => handleUserStatus(e, row._id)}
          // >
          //   <FontAwesomeIcon icon={faToggleOn} style={{ color: "green" }} />
          // </button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px",
       
      },
    ];
  
  
    const handleAdminStatus = (e, id) => {
      e.preventDefault();
      console.log("Row Id", id);
      dispatch(updateAdminStatus(id)).then(() => {
        dispatch(getAllAdmin(perPage, page));
      });
    };
  
    useEffect(() => {
     if (message?.status === "success") {
        toast.success(message.msg);
      }
      if (message?.status === "error") {
        toast.success(message.msg);
      }
    }, [message]);
  
    useEffect(() => {
      dispatch(getAllAdmin(perPage, page));
    }, [perPage, page, dispatch]);
  
    const handlePageChange = (page) => {
      dispatch(getAllAdmin(perPage, page));
      setPage(page);
    };
  
    const handlePerRowsChange = async (newPerPage, page) => {
      setPerPage(newPerPage);
    };
    if (!currentUser) {
      return <Navigate to="/" />;
    }
        return (
            <div>
                <div className="float-end mb-4">
                    <Link to="/createAdmin" className="btn button-lime button-lime-hover btn-block btn-lg text-white">
                        <strong>Create SubAdmin</strong>
                    </Link>
                </div>
                <DataTable
                    title="Administrators"
                    columns={columns}
                  data={adminList?.admin?.data}
                    pagination
                    paginationServer
                   paginationTotalRows={adminList?.admin?.totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                />
            </div>
        );
    }


export default Administration;