import {
  GET_ALLUSER_SUCCESS,
  POST_DEACTIVATE_USER_SUCCESS,
  POST_DELETE_USER_SUCCESS,
  POST_DELETE_NOTE_SUCCESS,
  SET_MESSAGE,
  GET_USER_DETAIL_SUCCESS
} from "./type";

import UserService from "../services/user.service";

export const getAllUser = (perPage,page,filter) => (dispatch) => {
  return UserService.getAllUser(perPage,page,filter).then(
    (response) => {
      dispatch({
        type: GET_ALLUSER_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    }
  );
};

export const updateUserStatus = (id) => (dispatch) => {
  return UserService.updateUserStatus(id).then(
    (response) => {
      dispatch({
        type: POST_DEACTIVATE_USER_SUCCESS,
        payload: response,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });
      return Promise.resolve();
    },(error) => {
      
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // dispatch({
      //   type: REGISTER_FAIL,
      // });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteUser = (id) => (dispatch) => {
  return UserService.deleteUser(id).then(
    (response) => {
      dispatch({
        type: POST_DELETE_USER_SUCCESS,
        payload: response,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });
      return Promise.resolve();
    },(error) => {
      
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // dispatch({
      //   type: REGISTER_FAIL,
      // });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteNote = (id) => (dispatch) => {
  return UserService.deleteNote(id).then(
    (response) => {
      dispatch({
        type: POST_DELETE_NOTE_SUCCESS,
        payload: response,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });
      return Promise.resolve();
    },(error) => {
      
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getUserDetail = (id,PurchasePerPage,PurchasePage,TransferPerPage,TransferPage, ReferralPerPage, ReferralPage, NotesPerPage, NotesPage) => (dispatch) => {
  return UserService.getUserDetail(id,PurchasePerPage,PurchasePage,TransferPerPage,TransferPage, ReferralPerPage, ReferralPage, NotesPerPage, NotesPage).then(
    (response) => {
      dispatch({
        type: GET_USER_DETAIL_SUCCESS,
        payload: response,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });
      return Promise.resolve();
    },(error) => {
      
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};




