import {
  SET_PURCHASES,
  SET_TRANSFERS,
  SET_PAYMENT_TOKEN,
  SET_TOKEN_CONFIG,
  POST_DEACTIVATE_ADMIN_SUCCESS,
  GET_ALLADMIN_SUCCESS,
  GET_ALLADMIN_FAIL,
  CREATE_Airdrop_SUCCESS,
  CREATE_Airdrop_FAIL
} from "../actions/type";

const initialState = { data: null };

export default function admin(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PURCHASES:
      return {
        data: {
          ...state.data,
          purchases: payload,
        },
      };
    case SET_TRANSFERS:
      return {
        data: {
          ...state.data,
          transfers: payload,
        },
      };
    case SET_PAYMENT_TOKEN:
      return {
        data: {
          ...state.data,
          paymentTokens: payload,
        },
      };
    case SET_TOKEN_CONFIG:
      return {
        data: {
          ...state.data,
          tokenConfig: payload,
        },
      };
    case POST_DEACTIVATE_ADMIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case GET_ALLADMIN_SUCCESS:
      return {
        data: {
          ...state.data,
          admin: payload,
        },
      };
    case GET_ALLADMIN_FAIL:
      return {
        data: {
          ...state.data,
          admin: payload,
        },
      };
      case CREATE_Airdrop_SUCCESS:
          return {
            ...state,
            isLoggedIn: false,
          };
        case CREATE_Airdrop_FAIL:
          return {
            ...state,
            isLoggedIn: false,
          };   
    default:
      return state;
  }
}
