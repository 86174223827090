import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_PROFILE_SUCCESS,
} from "./type";

import AuthService from "../services/auth.service";

export const register = (firstName, lastName, affilate_phoneNumber, email, password) => (dispatch) => {
  return AuthService.register(firstName, lastName, affilate_phoneNumber, email, password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: LOGIN_FAIL,
        // payload: error.response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {error: error.response.data.error},
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const setAuth =  () => (dispatch) => {
  const auth_user = JSON.parse(localStorage.getItem("user"));
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user: auth_user },
  }); 
}

export const getProfile = () => (dispatch) => {
  return AuthService.getProfile().then(
    (response) => {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    }
  );
};

export const adminForgotPassword = (email) => (dispatch) => {
  return AuthService.adminForgotPassword(email).then(
    (data) => {
      dispatch({
        type: FORGOT_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: FORGOT_FAIL,
        payload: error
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {error: error},
      });

      return Promise.reject();
    }
  );
};


export const adminResetPassword = (token, password) => (dispatch) => {
  return AuthService.adminResetPassword(token, password).then(
    (data) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: error
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {error: error},
      });

      return Promise.reject();
    }
  );
};