import {
    GET_ALLTOKENCONFIGS_SUCCESS,
    CREATE_TOKEN_SUCCESS,
    CREATE_TOKEN_FAIL,
    SET_MESSAGE
  } from "./type";
  
  import TokenService from "../services/token.sevice";
  
  export const getTokenConfigs = (perPage,page) => (dispatch) => {
    return TokenService.getTokenConfigs(perPage,page).then(
      (response) => {
        dispatch({
          type: GET_ALLTOKENCONFIGS_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      }
    );
  };

  export const createToken = (symbolName, minPurchase, maxPurchase, tokenPrice, tokenContractAddress, chainId, percentage, processingFee) => (dispatch) => {
    return TokenService.createToken(symbolName, minPurchase, maxPurchase, tokenPrice, tokenContractAddress, chainId, percentage, processingFee).then(
      (response) => {
        dispatch({
          type: CREATE_TOKEN_SUCCESS,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: response.data,
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          error.response;
  
        dispatch({
          type: CREATE_TOKEN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  

  
  
  
  
  