import axios from "axios";
import authHeader from "./auth-header";
import Web3 from "web3";
import Swal from "sweetalert2";
let web3 = new Web3();

const API_URL = process.env.REACT_APP_API_URL;

const getTokenConfigs = (limit, page) => {
  return axios
    .get(API_URL + "/tokenConfig/list?limit=" + limit + "&page=" + page, {
      headers: authHeader(),
    })
    .then((response, err) => {
      if (response) {
        return response;
      }
      return err;
    });
};

const createToken = (symbolName, minPurchase, maxPurchase, tokenPrice, tokenContractAddress, chainId, percentage, processingFee) => {
  return axios.post(
    API_URL + "/tokenConfig/create",
    {
      symbolName,
      minPurchase,
      maxPurchase,
      tokenPrice,
      tokenContractAddress,
      chainId,
      percentage,
      processingFee
    },
    { headers: authHeader() }
  );
};

const switchNetwork = async (
  chainId,
  name,
  rpc,
  native_name,
  native_symbol,
  decimals,
  explorer,
  connector
) => {
  try {
    if (localStorage.getItem("walletconnect")) {
      web3 = new Web3(connector);
    } else {
      web3 = new Web3(window.ethereum);
    }
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chainId,
              chainName: name,
              rpcUrls: [rpc],
              nativeCurrency: {
                name: native_name,
                symbol: native_symbol,
                decimals: decimals,
              },
              blockExplorerUrls: [explorer],
            },
          ],
        });
      } catch (error) {
        Swal.fire({
          confirmButtonColor: "#d3bd0e",
          icon: "error",
          title: "error",
          showConfirmButton: false,
          text: error.message,
        });
        throw error;
      }
    } else {
      throw error;
    }
  }
};

const TokenService = {
  getTokenConfigs,
  createToken,
  switchNetwork
};

export default TokenService;
