import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = "http://devapi.byobkingdomcurrency.com/";
const API_URL = process.env.REACT_APP_API_URL;

const getPurchases = async (filterText) => {
	const response = await axios.get(API_URL + "/admin/purchases/get/all?filter=" + filterText, { headers: authHeader() });
	return response;
};


const getAdminPurchases = async (filterText) => {
	const response = await axios.get(API_URL + "/admin/transfer/get/all?filter=" + filterText, { headers: authHeader() });
	return response;
};

const getTransfers = async () => {
	const response = await axios.get(API_URL + "/transfers/get/all", { headers: authHeader() });
	return response;
};

const getAdmins = async () => {
	const response = await axios.get(API_URL + "/admin/get/all", { headers: authHeader() });
	return response;
};

const getPaymentToken = async () => {
	const response = await axios.get(API_URL + "/admin/payments/get/all", { headers: authHeader() });
	return response;
};

const tokenConfig = async (symbol) => {
    const response = await axios
		.get(API_URL + "/admin/tokenconfig/", { headers: authHeader() });
	return response;
}

const updateStatus = async (id) => {
	const response = await axios.post(API_URL + "/admin/updateTokenStatus", { id }, { headers: authHeader() });
	return response;
};

const updatePaymentDetails = async (row) => {
	const response = await axios.post(API_URL + "/admin/updateTokenDetails", { row }, { headers: authHeader() });
	return response;
};

const saveRecord = async (id, hash) => {
	const response = await axios
		.post(
			API_URL + "/admin/transfers/saveTransferDetails",
			{ id: id, txId: hash.hash },
			{ headers: authHeader() }
		);
	return response;
};

const fetchHash = async (id, hash) => {
	const response = await axios
		.post(
			API_URL + "/admin/transfers/updateStatus",
			{ id: id, txId: hash.from },
			{ headers: authHeader() }
		);
	return response;
};



//sarbjit 

const updateAdminStatus = async (id) => {
  const response = await axios
		.post(API_URL + "/admin/updateAdminStatus", {
			id,
		}, { headers: authHeader() });
	return response.data;
};

const getAllAdmin = async (limit,page) => {
  const response = await axios.get(API_URL + "/admin/get/all/?limit=" + limit + "&page=" + page, { headers: authHeader() });
	if (response) {
		console.log(response, "-----------admijn");
		return response;
	}
};

//sarbjit end
const updateKYCStatus = async (id, type, comments) => {
	const response = await axios
		.post(API_URL + "/admin/updateKYCStatus", { id, type, comments }, { headers: authHeader() });
	return response;
};

const sendNote = async (text, user, image) => {
	const response = await axios
		.post(API_URL + "/admin/notes/save", { text: text, user: user._id, image: image }, { headers: authHeader() });
	return response;
};

const upload = async (formdata) => {
  const res = await axios.post(API_URL + "/admin/upload", formdata, { headers: authHeader() });
	return res;
};

const editProfile = async (data) => {
	const res = await axios.post(API_URL + "/admin/profile/edit", data, { headers: authHeader() });
	return res;
}

const createAirdrop= async (user_id,amount) => {
	const res = await axios.post(API_URL + "/admin/airdrop", {user_id,amount}, { headers: authHeader() });
	return res;
}

const AdminService = {
  getPurchases,
  getAdminPurchases,
  getTransfers,
  getAdmins,
  getPaymentToken,
  updateStatus,
  updatePaymentDetails,
  saveRecord,
  getAllAdmin,
  updateAdminStatus,
  updateKYCStatus,
  sendNote,
  upload,
  tokenConfig,
  editProfile,
  fetchHash,
  createAirdrop,
};

export default AdminService;
