import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import message from "./message";
import token from "./token";
import admin from "./admin";


export default combineReducers({
  auth,
  user,
  message,
  token,
  admin,

});