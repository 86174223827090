import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getTokenConfigs, createToken} from "../actions/token";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

function TokenConfig() {

  const form = useRef();


  const [symbolName, setSymbolName] = useState("");
  const [tokenPrice, setCurrentPrice] = useState("");
  const [minPurchase, setMinPurchase] = useState("");
  const [maxPurchase, setMaxPurchase] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [chainId, setChainId] = useState("");
  const [percentage, setPercentage] = useState("");
  const [processingFee, setProcessingFee] = useState("");

const { message } = useSelector((state) => state.message);
  const tokenList = useSelector((state) => state.token);
  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
//console.log(message,"-------------------message");

  const onChangeSymbolName = (e) => {
    const symbolName = e.target.value;
    setSymbolName(symbolName);
  };

  const onChangeCurrentPrice = (e) => {
    const tokenPrice = e.target.value;
    setCurrentPrice(tokenPrice);
  };

  const onChangeMaxPurchase = (e) => {
    const maxPurchase = e.target.value;
    setMaxPurchase(maxPurchase);
  };

  const onChangeMinPurchase = (e) => {
    const minPurchase = e.target.value;
    setMinPurchase(minPurchase);
  };

  const onChangeContractAddress = (e) => {
    const contractAddress = e.target.value;
    setContractAddress(contractAddress);
  };

  const onChangeChainID = (e) => {
    const chain  = Number(e.target.value);
    setChainId(chain);
  };

  const onChangePercentage = (e) => {
    const percent  = Number(e.target.value);
    setPercentage(percent);
  };

  const onChangeProcessingFee = (e) => {
    const processingFee  = Number(e.target.value);
    setProcessingFee(processingFee);
  };


  

  const handleCreateToken = (e) => {
    e.preventDefault();

        dispatch(createToken(symbolName, minPurchase, maxPurchase, tokenPrice, contractAddress, chainId, percentage, processingFee)).then(async () => {
          await dispatch(getTokenConfigs(perPage, page))
          setCurrentPrice("")
          setMinPurchase("")
          setMaxPurchase("")
          setSymbolName("")
          setContractAddress("")
          setChainId("")
          setPercentage("")
          setProcessingFee("")
        });
        if (message && message.status === 'success') {
          toast.success(message.message);
        }
        if (message && message.status === 'error') {
          toast.error(message.message);
        }
       
  };
  

  const columns = [
    {
      name: "Symbol",
      selector: (row) => row.symbol,
      width: "100px",
      sortable: true,
    },
    {
      name: "Max Purchase",
      selector: (row) => "$"+row.maxPurchaseAmount.toFixed(2),
      width: "120px",
      sortable: true,
    },
    {
      name: "Min Purchase",
      selector: (row) => "$"+row.minPurchaseAmount.toFixed(2),
      width: "120px",
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => "$"+row.tokenPrice,
      width: "80px",
      sortable: true,
    },
    {
      name: "Processing Fee",
      selector: (row) => "$"+row.processingFee.toFixed(2),
      width: "150px",
      sortable: true,
    },
    {
      name: "Referral Percentage",
      selector: (row) => row.rewardPercentage ? row.rewardPercentage + '%':'0 %',
      width: "150px",
    },
    {
      name: "Token Contract Address",
      selector: (row) => row?.tokenContractAddress,
      width: "190px",
      sortable: true,
    },
    {
      name: "Chain ID",
      selector: (row) => row?.chainId ? row?.chainId : "--",
      width: "100px",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>  row && row.isActive === false ? (
        "Inactive"
      ) : ('Active'),
      width: "100px",
      sortable: true,
    },
    {
      name: "Active Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      width: "150px",
    },
    {
      name: "Deactive Date",
      selector: (row) => row && row.isActive === true ? (
        ""
      ) : (new Date(row.updatedAt).toLocaleDateString()),
      width: "150px",
    },
    {
      name: "Admin Name",
      selector: (row) => row.adminId?.firstName +" "+ row.adminId?.lastName ,
      width: "120px",
      sortable: true,
    },
  ];



  useEffect(() => {
    dispatch(getTokenConfigs(perPage, page));
  }, [perPage, page, dispatch]);

  const handlePageChange = (page) => {
    dispatch(getTokenConfigs(perPage, page));
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="TokenConfig">
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    <div className="container mb-4">
      <div className="row justify-content-md-center">
        <div className="col col-6 bg-white p-4 mb-4">
        <h4 className="color-lime text-center"><strong>Create Token</strong></h4>
        <Form onSubmit={handleCreateToken} ref={form}>
          <div className="form-group">
            <Input
              placeholder="Symbol Name"
              type="text"
              required
              className="form-control mt-3"
              name="symbolName"
              value={symbolName}
              onChange={onChangeSymbolName}
            />
          </div>
          <div className="row mb-4">
            <div className="form-group col-6">
              <Input
                placeholder="Current Price"
                type="number"
                required
                className="form-control mt-3"
                name="tokenPrice"
                value={tokenPrice}
                onChange={onChangeCurrentPrice}
              
              />
            </div>
            <div className="form-group col-6">
              <Input
                placeholder="Processing Fee"
                type="number"
                required
                className="form-control mt-3"
                name="processingFee"
                value={processingFee}
                onChange={onChangeProcessingFee}
              
              />
            </div>
          </div>
          <div className="row mb-4">
          <div className="form-group col-6">
            <Input
              placeholder="Min Purchase"
              type="number"
              required
              className="form-control mt-3"
              name="minPurchase"
              value={minPurchase}
              onChange={onChangeMinPurchase}
            />
          </div>

          <div className="form-group col-6">
            <Input
              placeholder="Max Purchase"
              required
              type="number"
              className="form-control mt-3"
              name="maxPurchase"
              value={maxPurchase}
              onChange={onChangeMaxPurchase}
            />
          </div>
          </div>

          <div className="form-group">
            <Input
              placeholder="Token Contract Address"
              type="text"
              required
              className="form-control mt-3"
              name="contractAddress"
              value={contractAddress}
              onChange={onChangeContractAddress}
            />
          </div>
          <div className="row mb-4">
          <div className="form-group col-6">
            <Input
              placeholder="Chain ID"
              type="text"
              required
              className="form-control mt-3"
              name="chainId"
              value={chainId}
              onChange={onChangeChainID}
            />
          </div>
          <div className="form-group col-6">
            <Input
              placeholder="Affiliated Reward %"
              type="text"
              required
              className="form-control mt-3"
              name="percentage"
              value={percentage}
              onChange={onChangePercentage}
            />
          </div>
          </div>

          <div className="form-group mt-3 text-center ">
            <button
              className="btn button-lime button-lime-hover btn-block btn-lg text-white"
            >
           
              <span>Create</span>
            </button>
          </div>
        </Form>
        </div>
     
      </div>
    </div>
      <div className="pt-4 bg-white">
      <h4 className="color-lime text-center"><strong>Token List</strong></h4>
      <hr className="color-lime"></hr>
      <DataTable
        title="Token Configurations"
        columns={columns}
        data={tokenList?.token?.data}
        pagination
        paginationServer
        paginationTotalRows={tokenList?.token?.totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
      </div>
     
    </div>
  );
}

export default TokenConfig;
