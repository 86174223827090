export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";
export const GET_ALLUSER_SUCCESS = "GET_ALLUSER_SUCCESS";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_ALLUSER_FAIL = "GET_ALLUSER_FAIL";
export const POST_DEACTIVATE_USER_SUCCESS = "POST_DEACTIVATE_USER_SUCCESS"
export const POST_DELETE_USER_SUCCESS = "POST_DELETE_USER_SUCCESS"
export const POST_DELETE_NOTE_SUCCESS = "POST_DELETE_NOTE_SUCCESS"
export const GET_ALLTOKENCONFIGS_SUCCESS  = "GET_ALLTOKENCONFIGS_SUCCESS"
export const CREATE_TOKEN_SUCCESS = "CREATE_TOKEN_SUCCESS"
export const  CREATE_TOKEN_FAIL = "CREATE_TOKEN_FAIL"
export const SET_PURCHASES = "SET_PURCHASES"
export const SET_TRANSFERS = "SET_TRANSFERS"
export const SET_PAYMENT_TOKEN = "SET_PAYMENT_TOKEN"
export const SET_TOKEN_CONFIG = "SET_TOKEN_CONFIG"
export const POST_DEACTIVATE_ADMIN_SUCCESS = "POST_DEACTIVATE_ADMIN_SUCCESS"
export const GET_ALLADMIN_SUCCESS = "GET_ALLADMIN_SUCCESS"
export const GET_ALLADMIN_FAIL = "GET_ALLADMIN_FAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const FORGOT_FAIL = "FORGOT_FAIL";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CREATE_Airdrop_FAIL ="CREATE_Airdrop_FAIL";
export const CREATE_Airdrop_SUCCESS ="CREATE_Airdrop_SUCCESS";


