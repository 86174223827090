import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Swal from "sweetalert2";
import logo from "../assets/logo.png";

import { login } from "../actions/auth";
import { isEmail } from "validator";
const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <p className="text-danger" role="alert">
        This is not a valid email.
      </p>
    );
  }
};

const required = (value) => {
  if (!value) {
    return (
      <p className="text-danger" role="alert">
        This field is required!
      </p>
    );
  }
};

if (window.location.search === "?confirm=thankyou") {
  Swal.fire({
    confirmButtonColor: "#d3bd0e",
    title: "<strong>Welcome to KingdomCurrency</strong>",
    icon: "success",
    html: "Your account is activated.<br/>Please login using your email and password",
    showCloseButton: true,
  }).then(function (result) {
    if(result.isConfirmed){
      window.location.href = "/";
    }
    
  });
}
if (window.location.search === "?confirm=error") {
  Swal.fire({
    confirmButtonColor: "#d3bd0e",
    title: "<strong>Welcome to KingdomCurrency</strong>",
    icon: "error",
    html: "Resend the verification email",
    showCloseButton: true,
  }).then(function (result) {
    if(result.isConfirmed){
      window.location.href = "/";
    }
  });

  };

const Login = (props) => {

  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);



  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(email, password))
        .then(() => {
          navigate("/users");

        })
        .catch(() => {
          if (message) {
            console.log(message.error, "login failed");
          }

          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/users" />;
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src={logo}
          alt="logo"
          className="profile-img-card"
        />

        {message && message?.error?.statusCode === 401 && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message.error.msg}
            </div>
          </div>
        )}

        {message && message?.error?.statusCode === 202 && (
          <Navigate to="/resendLink" />
        )}
        <Form onSubmit={handleLogin} ref={form}>
          <div className="form-group">
            <Input
              placeholder="Email"
              type="text"
              className="form-control mt-3"
              name="email"
              value={email}
              onChange={onChangeEmail}
              validations={[validEmail, required]}
            />
          </div>

          <div className="form-group">
            <Input
              placeholder="Password"
              type="password"
              className="form-control mt-3"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>
          <div className="d-flex justify-content-between mx-3 my-4">
            <div className="form-check">
            </div>

            <a href={"/forgotPassword"} className="text-decoration-none">
              Forgot password?
            </a>
          </div>

          <div className="form-group mt-2 text-center d-grid gap-2">
            <button
              className="btn button-lime button-lime-hover btn-block btn-lg"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Login</span>
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Login;
