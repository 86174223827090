import {
  SET_PURCHASES,
  SET_TRANSFERS,
  SET_PAYMENT_TOKEN,
  SET_TOKEN_CONFIG,
  POST_DEACTIVATE_ADMIN_SUCCESS,
  SET_MESSAGE,
  GET_ALLADMIN_SUCCESS,
  CREATE_Airdrop_FAIL,
  CREATE_Airdrop_SUCCESS,
} from "./type";

import AdminService from "../services/admin.service";

export const setPurchases = (filterText) => (dispatch) => {
  return AdminService.getPurchases(filterText).then(
    (response) => {
      dispatch({
        type: SET_PURCHASES,
        payload: response.data.data,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const setTransfer = (filterText) => (dispatch) => {
  return AdminService.getAdminPurchases(filterText).then(
    (response) => {
      dispatch({
        type: SET_PURCHASES,
        payload: response.data.data,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const setTransfers = () => (dispatch) => {
  return AdminService.getTransfers().then(
    (response) => {
      dispatch({
        type: SET_TRANSFERS,
        payload: response.data.data,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const setPaymentTokens = () => (dispatch) => {
  return AdminService.getPaymentToken().then(
    (response) => {
      dispatch({
        type: SET_PAYMENT_TOKEN,
        payload: response.data.token,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const changePaymentStatus = (id) => (dispatch) => {
  return AdminService.updateStatus(id).then(
    (response) => {
      dispatch({
        type: SET_PAYMENT_TOKEN,
        payload: response.data.token,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const changePaymentDetails = (row) => (dispatch) => {
  return AdminService.updatePaymentDetails(row).then(
    (response) => {
      dispatch({
        type: SET_PAYMENT_TOKEN,
        payload: response.data.token,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const updateAdminStatus = (id) => (dispatch) => {
  return AdminService.updateAdminStatus(id).then(
    (response) => {
      dispatch({
        type: POST_DEACTIVATE_ADMIN_SUCCESS,
        payload: response,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getAllAdmin = (perPage, page) => (dispatch) => {
  return AdminService.getAllAdmin(perPage, page).then((response) => {
    dispatch({
      type: GET_ALLADMIN_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve();
  });
};

export const tokenConfig = (symbol) => (dispatch) => {
  return AdminService.tokenConfig(symbol).then(
    (response) => {
      dispatch({
        type: SET_TOKEN_CONFIG,
        payload: response.data.data,
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};
export const createAirdrop = (user_id, amount) => (dispatch) => {
  return AdminService.createAirdrop(user_id, amount).then(
    (response) => {
      dispatch({
        type: CREATE_Airdrop_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        error.response;

      dispatch({
        type: CREATE_Airdrop_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
