import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  faToggleOff,
  faToggleOn,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { updateUserStatus, getAllUser, deleteUser } from "../actions/user";
import { ToastContainer, toast } from "react-toastify";
import NoImage from "../assets/noImage.png";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import AdminService from "../services/admin.service";
import Swal from "sweetalert2";

function Dashboard() {
  const { message } = useSelector((state) => state.message);
  const userList = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(1);
  const [selectedUser, setSelectedUser] = useState("");
  const [show, setShow] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [comments, setComments] = useState("");
  let delay;

  let navigate = useNavigate();

  const closeNote = () => {
    setShowNote(false);
    setSelectedUser("");
    setNoteDescription("");
    setSelectedImage("");
  };

  const closeModal = () => {
    setShow(false);
    setSelectedRow(null);
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const noteModal = () => setShowNote(true);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setShow(true);
    setComments(row.comments);
  };

  const handleOnClick = (row) => {
    navigate(`/userDetails/${row._id}`);
  };

  const handleUserStatus = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
    dispatch(updateUserStatus(id)).then(() => {
      dispatch(getAllUser(perPage, page, filterText));
      if (message?.status === "success") {
        toast.success(message.msg);
      }
      if (message?.status === "error") {
        toast.success(message.msg);
      }
    });
  };

  const handleDeleteUser = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
    dispatch(deleteUser(id)).then(() => {
      dispatch(getAllUser(perPage, page, filterText));
      if (message?.status === "success") {
        toast.success(message.msg);
      }
      if (message?.status === "error") {
        toast.success(message.msg);
      }
    });
  };

  const handleEditUser = (e, row) => {
    setSelectedUser(row);
    e.preventDefault();
    noteModal();
  };

  const saveNote = async () => {
    let result;
    if (selectedImage) {
      let file = selectedImage;
      let formData = new FormData();
      formData.append("file", file);
      result = await AdminService.upload(formData);
    }
    AdminService.sendNote(
      noteDescription,
      selectedUser,
      result?.data?.url
    ).then((res) => {
      closeNote();
      Swal.fire({
		confirmButtonColor: "#d3bd0e",
        icon: "success",
        title: "Saved",
        text: "Note against this user has been saved successfully!",
        timer: 3000,
      });
    });
  };

  const onApproval = (id, type, comments) => {
    AdminService.updateKYCStatus(id, type, comments).then((res) => {
      closeModal();
      dispatch(getAllUser(perPage, page, filterText));
      Swal.fire({
		confirmButtonColor: "#d3bd0e",
        icon: "info",
        title: "KYC Updated",
        text: "KYC request has been updated successfully",
        timer: 3000,
      });
    });
  };

  const fixDecimals = (item) => {
    if (item) {
      return item.toFixed(4);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.users.firstName + " " + row.users.lastName,
      width: "150px",
      sortable: true,
    },
    {
      name: "Email",
      //   selector: (row) => (
      //     <Link to={`/userDetails/${row.users._id}`}>{row.users.email}</Link>
      //   ),
      selector: (row) => row.users.email,
      width: "200px",
    },
    {
      name: "USD",
      selector: (row) => "$" + row.purchaseAmount.toFixed(2),
      width: "130px",
      sortable: true,
    },
    {
      name: "Tokens Transfered",
      selector: (row) =>
        row?.tokenTransfer ? fixDecimals(row.tokenTransfer) : 0,
      width: "130px",
      sortable: true,
    },
    {
      name: "KYC Status",
      selector: (row) =>
        row.users.KYCStatus ? (
          <Link onClick={(e) => handleRowClick(row.users)}>
            {row.users.KYCStatus}
          </Link>
        ) : (
          ""
        ),
      width: "120px",
      sortable: true,
    },
    {
      name: "Email Verified",
      selector: (row) =>
        row && row.users.isEmailVerified === true ? "Yes" : "No",
      width: "120px",
      sortable: true,
    },
    {
      name: "Notes",
      selector: (row) => (
        <button
          className="btn btn-outline btn-xs"
          onClick={(e) => handleEditUser(e, row.users)}
        >
          <i className="fa-solid fa-pen"></i>
        </button>
      ),
      allowOverflow: true,
      button: true,
      width: "100px",
    },
    {
      name: "Is Active",
      selector: (row) =>
        row?.users?.status === true ? (
          <div className="d-flex flex-row">
            <div className="p-1">
              <p className="m-0">
                <b>Active</b>
              </p>
            </div>
            <div className="p-1" onClick={(e) => handleUserStatus(e, row._id)}>
              <FontAwesomeIcon
                icon={faToggleOff}
                style={{ color: "green", fontSize: "20px" }}
              />
            </div>
            <div className="p-1">
              <p className="m-0">Inactive</p>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row">
            <div className="p-1">
              <p className="m-0">Active </p>
            </div>
            <div className="p-1" onClick={(e) => handleUserStatus(e, row._id)}>
              <FontAwesomeIcon
                icon={faToggleOn}
                style={{ color: "red", fontSize: "20px" }}
              />
            </div>
            <div className="p-1">
              <p className="m-0">
                <b>Inactive</b>{" "}
              </p>
            </div>
          </div>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "150px",
    },
    {
      name: "Selfie Picture",
      ignoreRowClick: false,
      cell: (row) => (
        <div className="img-table">
          <img src={row?.users?.kyc?.side || NoImage} alt="" />
        </div>
      ),
      width: "140px",
    },
    /*{
      name: "Delete user ",
      selector: (row) => (
        <button
          className="btn btn-outline btn-xs"
          onClick={(e) => handleDeleteUser(e, row._id)}
        >
          <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "100px",
    },*/
  ];

  const handlePageChange = (page) => {
    dispatch(getAllUser(perPage, page, filterText));
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  useEffect(() => {
    dispatch(getAllUser(perPage, page, filterText));
  }, [perPage, page, filterText, dispatch]);
  useEffect(() => {
    if (userList?.user?.data) {
      setUserData(userList?.user?.data);
    }
  });

  useEffect(() => {
    delay = setTimeout(() => {
      dispatch(getAllUser(perPage, page, filterText));
    }, 1000);

    return () => clearTimeout(delay);
  }, [perPage, page, filterText]);

  const onchange = (e) => {
    setFilterText(e.target.value);
    dispatch(getAllUser(perPage, page, filterText));
  };
  const keyDown = (e) => {
    if (e.key === "Enter") {
      clearTimeout(delay);
      dispatch(getAllUser(perPage, page, filterText));
      console.log("keyDown press and ready for api call");
    }
  };

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
      dispatch(getAllUser(perPage, page, filterText));
    }
  };

  //sarbjit
  const [filterText, setFilterText] = useState("");

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            className="filter-box"
            id="search"
            placeholder="Search"
            aria-label="Search Input"
            value={filterText}
            onChange={onchange}
            onKeyDown={keyDown}
            size="small"
          />
          {/* <button className="filter-button" type="button" onClick={handleClear}>
							X
						</button> */}
        </div>
      </>
    );
  });

  //end sarbjit

  return (
    <div className="">
      {/* <div className="float-end mb-4">
    <a href="/#"
              className="btn button-lime button-lime-hover btn-block btn-lg"
            >
              <strong>Create SubAdmin</strong>
            </a>
    </div> */}

      {/* <h1 className="welcome-text text-start mb-4 text-white">
            Welcome {capitalizeFirstLetter(currentUser.data?.firstName) + ' ' + capitalizeFirstLetter(currentUser.data?.lastName)}
      </h1> */}
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />

      <DataTable
        title="Users"
        columns={columns}
        data={userList?.user?.data}
        pagination
        //onRowClicked={(row, event) => handleRowClick(row.users)}
        onRowClicked={(row, event) => handleOnClick(row.users)}
        paginationServer
        highlightOnHover
        pointerOnHover
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationTotalRows={userList?.user?.totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
      {/* <DataTable
			title="Contact List"
			columns={columns}
			data={userList?.user?.data}
			pagination
			paginationTotalRows={userList?.user?.totalRows}
			onChangePage={handlePageChange}
			onChangeRowsPerPage={handlePerRowsChange}
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
			// onChange={(e) => setFilterText(e.target.value)}
			
		/> */}
      <Modal
        size="xl"
        show={show}
        onHide={closeModal}
        centered
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-0 mb-4">
            <div className="col-md-6">
              <label className="mb-2">Email</label>
              <input
                type="text"
                className="form-control"
                value={selectedRow?.email}
                readOnly
              />
              <label className="mb-2">First Name</label>
              <input
                type="text"
                className="form-control"
                value={selectedRow?.firstName}
                readOnly
              />
              <label className="mb-2">Last Name</label>
              <input
                type="text"
                className="form-control"
                value={selectedRow?.lastName}
                readOnly
              />
              <label className="mb-2">Comments</label>
              <textarea
                className="form-control"
                name="comments"
                id=""
                cols="30"
                rows="5"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              ></textarea>
              <b>
                <label className="mb-2 text-center">Front Image of ID</label>
              </b>
              <div className="modal-img">
                {selectedRow?.kyc?.front?.split(".").pop() == "pdf" ? (
                  <div>
                    <object data={selectedRow?.kyc?.front} width="100%" />
                    <p>
                      {" "}
                      <a href={selectedRow?.kyc?.front} target="_blank">
                        Download Pdf
                      </a>
                    </p>
                  </div>
                ) : (
                  <img src={selectedRow?.kyc?.front || NoImage} alt="" />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <b>
                <label className="mb-2 text-center">Back Image of ID</label>
              </b>
              <div className="modal-img">
                {selectedRow?.kyc?.back?.split(".").pop() === "pdf" ? (
                  <div>
				  <object data={selectedRow?.kyc?.back} width="100%" />
				  <p>
					{" "}
					<a href={selectedRow?.kyc?.back} target="_blank">
					  Download Pdf
					</a>
				  </p>
				</div>
                ) : (
                  <img src={selectedRow?.kyc?.back || NoImage} alt="" />
                )}
              </div>
              <b>
                <label className="mb-2 text-center">Selfie</label>
              </b>
              <div className="modal-img">
                {selectedRow?.kyc?.side?.split(".").pop() === "pdf" ? (
                <div>
				<object data={selectedRow?.kyc?.side} width="100%" />
				<p>
				  {" "}
				  <a href={selectedRow?.kyc?.side} target="_blank">
					Download Pdf
				  </a>
				</p>
			  </div>
                ) : (
                  <img src={selectedRow?.kyc?.side || NoImage} alt="" />
                )}
              </div>
            </div>
            {selectedRow?.KYCStatus != "Approved" && (
              <div className="row justify-content-end pe-5">
                <div className="col-2">
                  <button
                    className="btn btn-danger"
                    onClick={() => onApproval(selectedRow?._id, 1, comments)}
                    disabled={
                      selectedRow?.kyc?.front == null ||
                      selectedRow?.kyc?.side == null ||
                      selectedRow?.kyc?.back == null ||
                      selectedRow?.KYCStatus == ""
                    }
                  >
                    Decline
                  </button>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-success"
                    onClick={() => onApproval(selectedRow?._id, 2, comments)}
                    disabled={
                      selectedRow?.kyc?.front == null ||
                      selectedRow?.kyc?.side == null ||
                      selectedRow?.kyc?.back == null ||
                      selectedRow?.KYCStatus == ""
                    }
                  >
                    Approve
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showNote} onHide={closeNote} centered animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Write a Note to User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-0 mb-4">
            <b>
              <label className="mb-2">Email</label>
            </b>
            <input
              type="text"
              className="form-control"
              value={selectedUser?.email}
              readOnly
            />
            <b>
              <label className="mb-2">Description</label>
            </b>
            <textarea
              placeholder="Enter Description for transfer"
              value={noteDescription}
              rows="10"
              onInput={(e) => setNoteDescription(e.target.value)}
            ></textarea>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div>
                <input accept="image/*" type="file" onChange={imageChange} />
                {selectedImage && (
                  <div className="img-table">
                    <img src={URL.createObjectURL(selectedImage)} alt="Thumb" />
                  </div>
                )}
              </div>
              <button
                className="btn button-lime button-lime-hover btn-block border-0 text-white"
                onClick={() => saveNote()}
                disabled={selectedUser === "" || noteDescription === ""}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Dashboard;
