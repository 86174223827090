import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";

import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  WagmiConfig,
  createClient,
  configureChains,
  mainnet,
  goerli,
} from "wagmi";
import {
  EthereumClient,
  modalConnectors,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { bsc, bscTestnet } from "@wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from 'wagmi/providers/infura'
import { INFURA_API_KEY, WALLET_CONNECT_ID,ALCHEMY_API_KEY } from "./constants/constants";


import { InjectedConnector } from '@wagmi/core/connectors'
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { LedgerConnector } from "wagmi/connectors/ledger";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";


const chains = [mainnet,bsc,bscTestnet,goerli];


const { provider, webSocketProvider } = configureChains(chains, [
  // alchemyProvider({apikey:ALCHEMY_API_KEY}),
  // infuraProvider({apikey:INFURA_API_KEY}),
  publicProvider(),
]);

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains: [mainnet,bsc,bscTestnet,goerli],
      options: {
        shimChainChangedDisconnect: true,
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true,
        name: (detectedName) =>
          `Injected (${
            typeof detectedName === "string"
              ? detectedName
              : detectedName.join(", ")
          })`,
      },
    }),
    new MetaMaskConnector({
      chains: [mainnet,bsc,bscTestnet,goerli],
      options: {
        shimChainChangedDisconnect: true,
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true,
      },
    }),
    new WalletConnectConnector({
      chains: [mainnet,bsc,bscTestnet,goerli],
      options: {
        qrcode: false,
        rpc: {
          1: `https://mainnet.infura.io/v3/${INFURA_API_KEY}`,
          5: `https://goerli.infura.io/v3/${INFURA_API_KEY}`,
          56: "https://bsc-dataseed.binance.org/",
          97: "https://data-seed-prebsc-1-s3.binance.org:8545/",
        },
      },
    }),
  ],
  provider,
  webSocketProvider,
});
const ethereumClient = new EthereumClient(client, chains);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <WagmiConfig client={client}>
        <App />
      </WagmiConfig>
      <Web3Modal
        projectId= {WALLET_CONNECT_ID}
        ethereumClient={ethereumClient}
        themeMode="light"
        themeColor="orange"
        themeBackground="gradient"
      />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
