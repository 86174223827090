import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentTokens, changePaymentStatus,changePaymentDetails } from "../actions/admin";
import {
  faToggleOff,
  faToggleOn,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";

const PaymentToken = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const [data, setData] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [show, setShow] = useState(false);

  const handleRow = (row) => {
    setCurrentRow(row);
    openModal();
  };

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setCurrentRow();
  };

  const columns = [
    {
      name: "Token Name",
      selector: (row) => row.tokenName,
      sortable: true,
    },
    {
      name: "Button Text",
      selector: (row) => row.buttonText,
      sortable: true,
    },
    {
      name: "Wallet Address",
      selector: (row) =>
        row.companyWalletAddress.slice(0, 5) +
        "....." +
        row.companyWalletAddress.slice(-5),
    },
    {
      name: "Status ",
      selector: (row) =>
        row && row.isActive === false ? (
          <div className="d-flex flex-row">
            <div className="p-1">
              <p className="m-0 text-muted">Active </p>
            </div>
            <div className="p-1" onClick={(e) => handlePaymentStatus(e, row._id)}>
              <FontAwesomeIcon
                icon={faToggleOn}
                style={{ color: "red", fontSize: "20px" }}
              />
            </div>
            <div className="p-1">
              <p className="m-0">Inactive </p>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row">
            <div className="p-1">
              <p className="m-0">Active </p>
            </div>
            <div className="p-1" onClick={(e) => handlePaymentStatus(e, row._id)}>
              <FontAwesomeIcon
                icon={faToggleOff}
                style={{ color: "green", fontSize: "20px" }}
              />
            </div>
            <div className="p-1">
              <p className="m-0 text-muted">Inactive </p>
            </div>
          </div>
          // <button
          //   className="btn btn-outline btn-xs"
          //   onClick={(e) => handlePaymentStatus(e, row._id)}
          // >
          //   <FontAwesomeIcon icon={faToggleOn} style={{ color: "green" }} />
          // </button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];

  const handlePaymentStatus = (e, id) => {
    e.preventDefault();
    dispatch(changePaymentStatus(id)).then(() => {
      dispatch(setPaymentTokens());
    });
  };

  const handleDetailsChange = (row,e) => {
    e.preventDefault();
    dispatch(changePaymentDetails(row)).then(() => {
        dispatch(setPaymentTokens());
      });
  };

  useEffect(() => {
    dispatch(setPaymentTokens()).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (admin.data) {
      setData(admin.data.paymentTokens);
    }
  });

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="container">
          <DataTable
            title="Payment Tokens"
            columns={columns}
            data={data}
            onRowClicked={(row, event) => {
              handleRow(row);
            }}
            pagination
            highlightOnHover
            pointerOnHover
          />
        </div>
        <Modal show={show} onHide={closeModal} centered animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Payment Token Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="mb-2">Token Name</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setCurrentRow({
                    ...currentRow,
                    tokenName: e.target.value,
                  });
                }}
                value={currentRow?.tokenName}
              />
              <label className="mb-2">Button Text</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setCurrentRow({
                    ...currentRow,
                    buttonText: e.target.value,
                  });
                }}
                value={currentRow?.buttonText}
              />
              <label className="mb-2">Company Wallet Address</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setCurrentRow({
                    ...currentRow,
                    companyWalletAddress: e.target.value,
                  });
                }}
                value={currentRow?.companyWalletAddress}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn button-lime button-lime-hover btn-block border-0" onClick={(e)=>{
                handleDetailsChange(currentRow,e);
                closeModal();
            }}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
};

export default PaymentToken;
