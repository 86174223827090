import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGOT_FAIL,
  FORGOT_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  GET_PROFILE_SUCCESS,
} from "../actions/type";

const auth_user = JSON.parse(localStorage.getItem("user"));
if(auth_user){
  var user = auth_user 
}

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      console.log(payload);
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
      case FORGOT_SUCCESS:
        return {
          ...state,
          forgot: payload,
        };
      case FORGOT_FAIL:
        return {
          ...state,
          forgot: payload,
        };
        case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          resetPass: payload,
        };
      case GET_PROFILE_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          profile: action.payload,
        };
    default:
      return state;
  }
}