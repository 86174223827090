import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation, NavLink } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import logo from "./assets/logo.png";

import Dashboard from "./components/Dashboard";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import Login from "./components/Login";
import Profile from "./components/Profile";
import TokenConfig from "./components/TokenConfig";
import PaymentToken from "./components/PaymentToken";
import Purchases from "./components/Purchases";
import Transfers from "./components/Transfers";
import Administration from "./components/Administration";
import CreateAdmin from "./components/Create-admin";
import UserDetails from "./components/User-details";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import { useAccount, useDisconnect,useConnect, useSignMessage } from "wagmi";
import { useWeb3Modal,Web3Button } from "@web3modal/react";
import Swal from "sweetalert2";
import { verifyMessage } from 'ethers/lib/utils'


const App = () => {
  const { isOpen, open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect({
    onSuccess(data) {
      console.log("Success", data);
    },
  });
  
  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage({
    message: 'Connecting to KindomCurrency Admin Panel.',
    onError(error) {
      console.log('Error', error)
      disconnect()
    },
  })

  const {isConnected,address,isConnecting } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      if(isReconnected){
        console.log('Reconnected',address)
      }else{
        signMessage();
      }
    },
  });

  


  const capitalizeFirstLetter = (text) => {
    if (text) {
      return text[0].toUpperCase() + text.substr(1).toLowerCase();
    }
  };

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
    } else {
    }
  }, [currentUser]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-0">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className="profile-img-card img-fluid" />
          </a>
          {currentUser && (
            <h4 class="welcome-text text-start text-white">
              Welcome{" "}
              {capitalizeFirstLetter(currentUser?.data?.firstName) +
                " " +
                capitalizeFirstLetter(currentUser?.data?.lastName)}
            </h4>
          )}
          {currentUser && <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>}
          {/* {currentUser && <h3 className="text-white text-start">{capitalizeFirstLetter(b)}</h3>} */}

          {currentUser ? (
            <>
              <div>
                
                <div
                  className=" collapse navbar-collapse"
                  id="navbarNavDropdown"
                >
                  <ul className="navbar-nav ms-auto ">
                    <li className="nav-item">
                      <NavLink
                        className="nav-link mx-2"
                        aria-current="page"
                        to="/users"
                      >
                        Users
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/purchases"
                        className="nav-link mx-2"
                        href="#"
                      >
                        Purchases
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/transfers"
                        className="nav-link mx-2"
                        href="#"
                      >
                        Token Transfers
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/tokenConfig"
                        className="nav-link mx-2"
                        href="#"
                      >
                        Token Configuration
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/payment" className="nav-link mx-2" href="#">
                        Payment Tokens
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/administration"
                        className="nav-link mx-2"
                        href="#"
                      >
                        Administrators
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/profile" className="nav-link mx-2" href="#">
                        My Profile
                      </NavLink>
                    </li>
                    <li>
                      <a href="/" className="nav-link" onClick={logOut}>
                        Logout
                      </a>
                    </li>
                    <li>
                    
                    <Web3Button label="Connect Wallet"  icon="hide" />
                      {/* {!address && (
                        <a
                          className="btn button-lime button-lime-hover btn-block btn-lg mx-3"
                          onClick={()=>open()}
                        >
                          <strong>Connect Wallet</strong>
                        </a>
                      )}
                      {address && (
                        <a
                          className="btn button-lime button-lime-hover btn-block btn-lg mx-3"
                          onClick={disconnect}
                        >
                          {(!isLoading && (isSuccess || isConnected)) && <strong>
                            {address.slice(0, 6) + "...." + address.slice(-5)}
                          </strong>}
                          {isLoading && <strong>Waiting for Sign</strong>}
                        </a>
                      )} */}
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <div className="nav navbar-nav pull-xs-right"></div>
          )}
        </div>
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/users" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:resetToken" element={<ResetPassword />} />
          <Route path="/tokenConfig" element={<TokenConfig />} />
          <Route path="/purchases" element={<Purchases />} />
          <Route
            path="/transfers"
            element={<Transfers walletAddress={address}/>}
          />
          <Route path="/payment" element={<PaymentToken />} />
          <Route path="/administration" element={<Administration />} />
          <Route path="/createAdmin" element={<CreateAdmin />} />
          <Route path="/userDetails/:id" element={<UserDetails />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
