import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;


const getAllUser = (limit,page,filterText) => {
    return axios.get(API_URL + "/user/userList?limit=" + limit +"&page=" + page +"&filter=" + filterText, { headers: authHeader() }).then((response,err) => {
      if (response) {
        return response;
      }
      return err;
    
    });
  };

  const updateUserStatus = async (id) => {
    const response = await axios
      .post(API_URL + "/admin/updateUserStatus", {
        id,
      }, { headers: authHeader() });
    return response.data;
  };

  const deleteUser = async (id) => {
    const response = await axios
      .post(API_URL + "/admin/deleteUser", {
        id,
      }, { headers: authHeader() });
    return response.data;
  };

  const deleteNote = async (id) => {
    const response = await axios
      .post(API_URL + "/admin/deleteNote", {
        id,
      }, { headers: authHeader() });
    return response.data;
  };
  

  const getUserDetail = async (id,PurchasePerPage,PurchasePage,TransferPerPage,TransferPage, ReferralPerPage, ReferralPage, NotesPerPage, NotesPage) => {
    console.log(id,PurchasePerPage,PurchasePage,TransferPerPage,TransferPage, ReferralPerPage, ReferralPage, NotesPerPage, NotesPage);
    const response = await axios
      .post(API_URL + "/user/userDetail?purchaseLimit=" + PurchasePerPage + "&purchasePage=" + PurchasePage + "&transferLimit=" + TransferPerPage + "&transferPage=" + TransferPage + "&referralLimit=" + ReferralPerPage + "&referralPage=" + ReferralPage + "&notesLimit=" + NotesPerPage + "&notesPage=" + NotesPage, {
        id,
      }, { headers: authHeader() });
    return response.data;
  };

  const UserService = {
    getAllUser,
    updateUserStatus,
    deleteUser,
    getUserDetail,
    deleteNote
    
}

export default UserService;