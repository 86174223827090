import React,{useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { adminForgotPassword } from "../actions/auth";
import { isEmail } from "validator";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";



const ForgotPassword = (props) => {
    const navigate = useNavigate();
  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <p className="text-danger" role="alert">
          This is not a valid email.
        </p>
      );
    }
  };
  
  const required = (value) => {
    if (!value) {
      return (
        <p className="text-danger" role="alert">
          This field is required!
        </p>
      );
    }
  };

  const [email, setEmail] = useState("");


  const forgotMsg = useSelector((state) => state.auth.forgot);
  const { message } = useSelector((state) => state.message);
  console.log(forgotMsg);

  useEffect(() => {
    if(forgotMsg?.status === "success"){
        toast.success(forgotMsg?.msg, {
        onClose: () =>  navigate("/") });
    }
    if(forgotMsg?.status === "error"){
      toast.error(forgotMsg?.msg,{
        onClose: () =>  navigate("/") });
    }
  }, [forgotMsg]);
  const dispatch = useDispatch();
  const form = useRef();
  const checkBtn = useRef();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

 


  const handleSubmit = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(adminForgotPassword(email));
    }
  };
  console.log(message,"-------------------------");
  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleSubmit} ref={form}>
          <div className="form-group">
          <Input
              placeholder="Email"
              type="text"
              className="form-control mt-3"
              name="email"
              value={email}
              onChange={onChangeEmail}
              validations={[validEmail, required]}
            />
          </div>
          <div className="form-group  mt-4 text-center d-grid gap-2 mb-4">
                <button className="btn button-lime button-lime-hover btn-block">
              Forgot Password
            </button>
          </div>

          <div className="text-center mt-4">
            <a href={"/"} className="text-decoration-none">
              Back to Login
            </a>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default ForgotPassword;
