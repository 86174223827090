import {
  CREATE_TOKEN_FAIL,
  CREATE_TOKEN_SUCCESS,
  GET_ALLTOKENCONFIGS_SUCCESS,
  } from "../actions/type";
  
  const auth_user = localStorage.getItem("user");
  if(auth_user){
    var user = auth_user 
  }
  
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
  
  export default function usr(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
     
      case GET_ALLTOKENCONFIGS_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          token: action.payload,
        }; 
        case CREATE_TOKEN_SUCCESS:
          return {
            ...state,
            isLoggedIn: false,
          };
        case CREATE_TOKEN_FAIL:
          return {
            ...state,
            isLoggedIn: false,
          };   
      default:
        return state;
    }
  }