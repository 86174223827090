import React, { useEffect,useState } from "react";
import { getProfile, setAuth } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import AdminService from "../services/admin.service";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";

const Profile = () => {
  const ProfileData = useSelector((state) => state.auth.profile);
  const [editBody,setEditBody] = useState({})
  const [isChange,setIsChange] = useState(false);
  const dispatch = useDispatch();

  const saveProfileChanges = () => {
    setIsChange(false);
    AdminService.editProfile(editBody).then((res)=> {
      let c = JSON.parse(localStorage.getItem('user'));
      c.data.firstName = editBody?.firstName;
      c.data.lastName = editBody?.lastName;
      c.data.phoneNumber = editBody?.phoneNumber;
      localStorage.setItem('user', JSON.stringify(c));
      dispatch(setAuth());
      console.log(res);
      Swal.fire({
        confirmButtonColor: "#d3bd0e",
        icon:'success',
        text:'Profile updated successfully!',
        timer:3000,
        timerProgressBar :true,
      })
    })
  };
  
  useEffect(() => {
    
    dispatch(getProfile())
  
  },[dispatch])

  useEffect(() => {
    setEditBody({firstName:ProfileData?.data?.firstName || '', lastName:ProfileData?.data?.lastName || '', phoneNumber : ProfileData?.data?.phoneNumber || ''});
  }, [ProfileData])
  

  return (
    <div className="container rounded bg-white m-5">
      <div className="row">
        <div className="col-md-3 ">
        </div>
        <div className="col border-right">
          <div className="p-3 py-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="text-right">Profile Settings</h4>
            </div>
            <div className="col-md-12">
                <label className="labels">Email ID</label>
                <input
                 readOnly={true}
                 disabled
                 // type="text"
                  className="form-control"
                  placeholder="enter email id"
                 value={ProfileData?.data?.email}
                />
                {/* <p>{ProfileData?.data?.email}</p> */}
              </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <label className="labels">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="first name"
                  value={editBody?.firstName}
                      onChange={(e) => {
                        setEditBody({ ...editBody, firstName: e.target.value });
                        setIsChange(true);
                      }}
                />
              </div>
              <div className="col-md-6">
                <label className="labels">Last Name</label>
                <input
                 // type="text"
                  className="form-control"
                  value={editBody?.lastName}
                      onChange={(e) => {
                        setEditBody({ ...editBody, lastName: e.target.value });
                        setIsChange(true);
                      }}
                  placeholder="last name"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label className="labels">Mobile Number</label>
                <input
                 // type="text"
                  className="form-control"
                  placeholder="enter phone number"
                  value={editBody?.phoneNumber}
                      onChange={(e) => {
                        setEditBody({ ...editBody, phoneNumber: e.target.value });
                        setIsChange(true);
                      }}
                />
              </div>
            </div>
            <div className="mt-5 text-center">
              <button className="btn btn-primary profile-button" type="button" disabled={!isChange} onClick={()=> saveProfileChanges()}>
                Save Profile
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
           
        </div>
      </div>
    </div>
  );
};

export default Profile;
