import React,{ useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { setPurchases,  setTransfer, tokenConfig } from "../actions/admin";
import moment from "moment";
import Swal from "sweetalert2";
import AdminService from "../services/admin.service";
import { Modal } from "react-bootstrap";


import {
  erc20ABI,
  useContractWrite,
  useAccount,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import Web3 from "web3";
const web3 = new Web3(window.ethereum)
let delay;

const Transfers = ({ walletAddress }) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [show, setShow] = useState(false);
  const [arr, setArr] = useState([]);
  const [filterText, setFilterText] = useState('');
  const fixDecimals = (item) => {
    if (item) {
      return item.toFixed(4);
    }
  };
  const columns = [
    {
      name: "Request Date",
      selector: (row) =>
        row.transferRequestDate
          ? moment(Number(row.transferRequestDate)).format("DD-MMM-YYYY h:mm A")
          : "-",
      width: "180px",
    },
    {
      name: "Transfer Date",
      selector: (row) =>
        row.transferDate
          ? moment(Number(row.transferDate)).format("DD-MMM-YYYY h:mm A")
          : "-",
      width: "180px",
    },
    {
      name: "Name",
      selector: (row) => row.userId?.firstName + " " + row.userId?.lastName,
      width: "140px",
      sortable: true,
    },
    {
      name: "Transfer Status",
      selector: (row) => row.transferStatus,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.userId?.email,
      width: "200px",
      sortable: true,
    },
    {
      name: "Amount in BYOB",
      selector: (row) => fixDecimals(row?.byobAmount),
      sortable: true,
    },

    {
      name: "Transfer Blockchain TxnId",
      selector: (row) =>
        row.transferBlockchainTxId
          ? row.transferBlockchainTxId.substring(0, 4) +
            "..." +
            row.transferBlockchainTxId.substring(
              row.transferBlockchainTxId.length - 4,
              row.transferBlockchainTxId.length
            )
          : "-",
    },
    {
      name: "Recepient Wallet",
      selector: (row) =>
        row.transferToWallet
          ? row.transferToWallet.slice(0, 5) +
            "...." +
            row.transferToWallet.slice(-5)
          : "-",
    },
    // {
    //   name: "Admin Wallet",
    //   selector: (row) =>
    //     row.transferFromWallet
    //       ? row.transferFromWallet.slice(0, 5) +
    //         "...." +
    //         row.transferFromWallet.slice(-5)
    //       : "-",
    // },
  ];

  const { switchNetwork } = useSwitchNetwork({
    chainId: admin.data?.tokenConfig?.chainId,
    onSuccess(data) {
      sendTransaction();
    },
  });

  const { address, isConnecting, isDisconnected } = useAccount()

  const { data, write } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: admin.data?.tokenConfig?.tokenContractAddress,
    abi: erc20ABI,
    functionName: "transfer",
    args: [
      selectedTransaction?.transferToWallet,
      Web3.utils.toWei(
        selectedTransaction?.byobAmount.toString() || "0",
        "ether"
      ),
    ],
    onSuccess(data){
      AdminService.saveRecord(selectedTransaction?._id, data).then((record) => {
        dispatch(setTransfer(filterText));
      });
    },
    onError(data) {
      console.log(data);
      Swal.close();
      Swal.fire({
        confirmButtonColor: "#d3bd0e",
        icon: "error",
        title: "Failed",
        text: "Transaction Failed! Transaction rejected by user OR You've insufficient balance...",
      });
    },
  });

  const {
    data: txHash,
    isLoading,
  } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess(data) {
      AdminService.fetchHash(selectedTransaction?._id,data).then((record)=>{
        dispatch(setTransfer(filterText));
        setSelectedTransaction(null);
        Swal.close();
        Swal.fire({
          confirmButtonColor: "#d3bd0e",
          icon: "success",
          title: "Success!",
          text: "Record has been saved successfully",
          timer: 2000,
        });
      })
    },
    onError(data) {
      console.log("Erroo", data);
      Swal.close();
    },
  });

  const sendTransaction = async () => {
    Swal.fire({
      confirmButtonColor: "#d3bd0e",
      title: "Processing",
      html: "Please proceed your transaction through wallet!",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    write();
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setSelectedRow(null);
  };

  const copyToClipboard = (a, i) => {
    navigator.clipboard.writeText(a);
    let array = [...arr];
    array.fill(false);
    array[i] = !array[i];
    setArr(array);
  };

 

  const rowDisabledCriteria = (row) => row.transferStatus === "completed" || row.transferStatus === "in-progress";

  const conditionalRowStyles = [
    {
      when: (row) => row.transferStatus === "completed",
      style: {
        backgroundColor: "green",
        color: "white",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
    // You can also pass a callback to style for additional customization
    {
      when: (row) => row.transferStatus === "pending",
      style: (row) => ({ backgroundColor: "pink" }),
    },
  ];

  

  const rowSelectCritera = (row) => {
    if(row !== undefined) {
      console.log(row);
      setSelectedTransaction(row);
      // localStorage.setItem('row',JSON.stringify(row));
    }
  };

 
  
  const handleInProgress = () => {
    let arr = tableData.filter(
      (item) => item.transferStatus === "in-progress"
    );
    console.log(arr)
    if (arr) {
      arr.map((item) => {
        web3.eth.getTransaction(
          item.transferBlockchainTxId,
          function (err, result) {
            if (err) console.error(err);
            else {
              AdminService.fetchHash(item._id, result).then((res) => {
                dispatch(setTransfer(filterText));
                setSelectedTransaction(null);
              });
            }
          }
        );
      });
    }
  };

    	  //sarbjit

        const onchange = (e)=>{
          setFilterText(e.target.value)
          dispatch(setTransfer(filterText));
         }
         const keyDown = (e)=>{
           if (e.key === "Enter") {
           clearTimeout(delay)
           dispatch(setTransfer(filterText));
           console.log('keyDown press and ready for api call')
           }
         }
  
      
        const subHeaderComponentMemo = React.useMemo(() => {
      
          return (
            <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input type="text" className="filter-box" id="search" placeholder="Search"
                aria-label="Search Input"
                value={filterText}
                onChange = {onchange}
                onKeyDown={keyDown}
                 size="small" />
                {/* <button className="filter-button" type="button" onClick={handleClear}>
                X
              </button> */}
              </div>
          </>
          );
        });
  
    
    
      //end sarbjit

  useEffect(() => {
    
    if(tableData){
      handleInProgress();
    }
  }, [tableData])
  
  

  useEffect(() => {
    setArr(Array(3).fill(false));
    dispatch(setTransfer(filterText)).then(() => {
      setIsTableLoading(false);
    });
    dispatch(tokenConfig());
  }, []);

  useEffect(() => {
    if (admin.data) {
      setTableData(admin.data.purchases);
    }
  });

  if (isTableLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="container">
          <div className="mx-0 row justify-content-end mb-3">
            <div className="col-3 px-0">
              <button
                className="btn button-lime button-lime-hover w-100 text-white"
                disabled={
                  address === undefined ||
                  selectedTransaction === null ||
                  isLoading
                }
                onClick={() => switchNetwork()}
              >
                Transfer
              </button>
            </div>
          </div>
          <DataTable
            title="Token Transfers"
            columns={columns}
            data={tableData.filter(
              (item) => item.transferStatus !== "not requested" && item.isSuccessful === true
            )}
            pagination
            selectableRows
            onSelectedRowsChange={(row) =>
              rowSelectCritera(row?.selectedRows[0])
            }
            selectableRowDisabled={rowDisabledCriteria}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={(row, event) => handleRowClick(row)}
            highlightOnHover
            selectableRowsHighlight
            selectableRowsSingle
            pointerOnHover
            subHeader
				    subHeaderComponent={subHeaderComponentMemo}
          />
        </div>
        <Modal
          size="lg"
          show={show}
          onHide={closeModal}
          centered
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transfer Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mx-0 mb-4">
              <div className="col-md-6">
                <label className="mb-2">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.userId?.email}
                  readOnly
                />
                <label className="mb-2">Amount in USD</label>
                <input
                  type="text"
                  className="form-control"
                  value={"$" + selectedRow?.amountUSD}
                  readOnly
                />
                <label className="mb-2">Amount in BYOB</label>
                <input
                  type="text"
                  className="form-control"
                  value={fixDecimals(selectedRow?.byobAmount)}
                  readOnly
                />
                <label className="mb-2">Transaction Type</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.purchaseType}
                  readOnly
                />
                <label className="mb-2">Request Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedRow?.transferRequestDate
                      ? moment(Number(selectedRow?.transferRequestDate)).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )
                      : "-"
                  }
                  readOnly
                />
                <label className="mb-2">Transfer Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedRow?.transferDate
                      ? moment(Number(selectedRow?.transferDate)).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )
                      : "-"
                  }
                  readOnly
                />
                <label className="mb-2">Transfer Status</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.transferStatus}
                  readOnly
                />
                <label className="mb-2">Purchase Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={moment(Number(selectedRow?.purchaseDate)).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                  readOnly
                />
                <label className="mb-2">Recipient Wallet</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.transferToWallet}
                    readOnly
                  />
                  {!arr[0] && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyToClipboard(selectedRow?.transferToWallet, 0)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {arr[0] && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
                {/* <label className="mb-2">Admin Wallet</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.transferFromWallet}
                    readOnly
                  />
                  {!arr[1] && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyToClipboard(selectedRow?.transferFromWallet, 1)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {arr[1] && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div> */}
              </div>
              <div className="col-md-6">
                <hr />
                <b>Trasnaction ID</b>
                <label className="mb-2">Transfer Blockchain TxnId</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.transferBlockchainTxId}
                    readOnly
                  />
                  {!arr[2] && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyToClipboard(selectedRow?.transferBlockchainTxId, 2)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {arr[2] && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
                <label className="mb-2">Strip ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.stripeId || ''}
                  readOnly
                />
                <label className="mb-2">Reward Purchase ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.rewardPurchaseId || ''}
                  readOnly
                />
                <hr />
                <b>Token Information</b>
                <label className="mb-2">Token Symbol</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.tokenConfigId?.symbol}
                  readOnly
                />
                <label className="mb-2">Token Price</label>
                <input
                  type="text"
                  className="form-control"
                  value={"$"+selectedRow?.tokenConfigId?.tokenPrice}
                  readOnly
                />
                <label className="mb-2">Token Contract Address</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.tokenConfigId?.tokenContractAddress}
                  readOnly
                />
                <label className="mb-2">Processing Fee</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.tokenConfigId?.processingFee}
                  readOnly
                />
                <label className="mb-2">Token Id</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.tokenConfigId?._id}
                  readOnly
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default Transfers;
