import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { setPurchases } from "../actions/admin";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
let delay;

const Purchases = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [show, setShow] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);
  const [isCopiedStripe, setIsCopiedStripe] = useState(false);
  const [isCopiedPurchase, setIsCopiedPurchase] = useState(false);
  const [filterText, setFilterText] = useState("");

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setShow(true);
  };

  const fixDecimals = (item) => {
    if (item) {
      return item.toFixed(4);
    }
  };

  const closeModal = () => {
    setIsCopied(false);
    setIsCopied2(false);
    setIsCopiedStripe(false);
    setIsCopiedPurchase(false);
    setShow(false);
    setSelectedRow(null);
  };

  const copyToClipboard = (address) => {
    setIsCopied(true);
    navigator.clipboard.writeText(address);
    // window.clipboardData.setData("Text", address);
  };

  const copyTxToClipboard = (tx) => {
    setIsCopied2(true);
    navigator.clipboard.writeText(tx);
    // window.clipboardData.setData("Text", tx);
  };

  const copyStripeToClipboard = (tx) => {
    setIsCopiedStripe(true);
    navigator.clipboard.writeText(tx);
    // window.clipboardData.setData("Text", tx);
  };

  const copyPurchaseToClipboard = (tx) => {
    setIsCopiedPurchase(true);
    navigator.clipboard.writeText(tx);
    // window.clipboardData.setData("Text", tx);
  };

  const columns = [
    {
      name: "Purchase Date",
      selector: (row) =>
        moment(Number(row.purchaseDate)).format("DD-MMM-YYYY H:mm A"),
      width: "200px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.userId?.firstName + " " + row.userId?.lastName,
      width: "150px",
      sortable: true,
    },
    {
      name: "Purchase Status",
      selector: (row) =>
        row.purchaseType == "us_bank_account"
          ? row.paymentStatus
          : row.isSuccessful == true
          ? "succeeded"
          : "failed",
      width: "150px",
      sortable: true,
    },
    {
      name: "Transfer Status",
      selector: (row) => row.transferStatus,
      width: "150px",
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.userId?.email,
      width: "150px",
      sortable: true,
    },
    {
      name: "Amount USD",
      selector: (row) => "$" + row.amountUSD.toFixed(2),
      width: "100px",
      sortable: true,
    },
    {
      name: "Amount in BYOB",
      selector: (row) => fixDecimals(row?.byobAmount),
      width: "150px",
      sortable: true,
    },
    {
      name: "Purchase Type",
      selector: (row) => row.purchaseType.toLowerCase(),
      width: "100px",
      sortable: true,
    },
    {
      name: "Stripe ID",
      selector: (row) => row?.stripeId,
      width: "100px",
      sortable: true,
    },
    {
      name: "Blockchain TxnId",
      //show first and last 4 characters with ...
      selector: (row) =>
        row?.blockchainTxnId
          ? row?.blockchainTxnId?.substring(0, 4) +
            "..." +
            row?.blockchainTxnId?.substring(
              row?.blockchainTxnId?.length - 4,
              row?.blockchainTxnId?.length
            )
          : "...",
      width: "150px",
      sortable: true,
    },
    {
      name: "Purchase Wallet",
      selector: (row) =>
        row?.purchaseFromWallet
          ? row?.purchaseFromWallet?.slice(0, 6) +
            "...." +
            row?.purchaseFromWallet?.slice(-5)
          : "...",
      width: "150px",
      sortable: true,
    },
  ];

  //sarbjit

  const onchange = (e) => {
    setFilterText(e.target.value);
    dispatch(setPurchases(filterText));
  };
  const keyDown = (e) => {
    if (e.key === "Enter") {
      clearTimeout(delay);
      dispatch(setPurchases(filterText));
      console.log("keyDown press and ready for api call");
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            className="filter-box"
            id="search"
            placeholder="Search"
            aria-label="Search Input"
            value={filterText}
            onChange={onchange}
            onKeyDown={keyDown}
            size="small"
          />
          {/* <button className="filter-button" type="button" onClick={handleClear}>
							X
						</button> */}
        </div>
      </>
    );
  });

  //end sarbjit

  useEffect(() => {
    dispatch(setPurchases(filterText)).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (admin.data) {
      setData(admin.data.purchases);
    }
  });
  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="">
          <DataTable
            title="Purchases"
            columns={columns}
            data={data}
            onRowClicked={(row, event) => handleRowClick(row)}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            pagination
            highlightOnHover
            pointerOnHover
          />
        </div>
        <Modal
          size="xl"
          show={show}
          onHide={closeModal}
          centered
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Purchase Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mx-0 mb-4">
              <div className="col-md-6">
                <label className="mb-2">Purchase Id</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?._id}
                  readOnly
                />
                <label className="mb-2">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.userId?.email}
                  readOnly
                />
                <label className="mb-2">Amount in USD</label>
                <input
                  type="text"
                  className="form-control"
                  value={"$"+selectedRow?.amountUSD}
                  readOnly
                />
                <label className="mb-2">Amount in BYOB</label>
                <input
                  type="text"
                  className="form-control"
                  value={fixDecimals(selectedRow?.byobAmount)}
                  readOnly
                />
                <label className="mb-2">Transaction Type</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.purchaseType}
                  readOnly
                />
                <label className="mb-2">Credit Card Fee</label>
                <input
                  type="text"
                  className="form-control"
                  value={"$"+selectedRow?.creditCardFeeUSD}
                  readOnly
                />
                <label className="mb-2">Processing Fee</label>
                <input
                  type="text"
                  className="form-control"
                  value={"$"+selectedRow?.feeUSD}
                  readOnly
                />
                <label className="mb-2">Transfer Status</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedRow?.transferStatus}
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <label className="mb-2">Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={moment(Number(selectedRow?.purchaseDate)).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                  readOnly
                />
                <label className="mb-2">Purchase Wallet Address</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.purchaseFromWallet}
                    readOnly
                  />
                  {!isCopied && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyToClipboard(selectedRow?.purchaseFromWallet)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {isCopied && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
                <label className="mb-2">Blockchain Transaction ID</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.blockchainTxnId}
                    readOnly
                  />
                  {!isCopied2 && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyTxToClipboard(selectedRow?.blockchainTxnId)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {isCopied2 && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
                <label className="mb-2">Reward Purchase Transaction ID</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.rewardPurchaseId}
                    readOnly
                  />
                  {!isCopiedPurchase && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyPurchaseToClipboard(selectedRow?.rewardPurchaseId)
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {isCopiedPurchase && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
                <label className="mb-2">Stripe Transaction ID</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRow?.stripeId?.substring(
                      0,
                      selectedRow?.stripeId?.indexOf("_secret_")
                    )}
                    readOnly
                  />
                  {!isCopiedStripe && (
                    <div
                      className="ms-auto pointer copyIcon"
                      onClick={() =>
                        copyStripeToClipboard(
                          selectedRow?.stripeId?.substring(
                            0,
                            selectedRow?.stripeId?.indexOf("_secret_")
                          )
                        )
                      }
                    >
                      <i className="fa-solid fa-copy"></i>
                    </div>
                  )}
                  {isCopiedStripe && (
                    <div className="ms-auto pointer text-primary copyIcon">
                      <small>Copied!</small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default Purchases;
