import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../actions/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const required = (value) => {
  if (!value) {
    return (
      <p className="text-danger" role="alert">
        This field is required!
      </p>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <p className="text-danger" role="alert">
        This is not a valid email.
      </p>
    );
  }
};

const vfirstName = (value) => {
  if (value.length < 4 || value.length > 20) {
    return (
      <p className="text-danger" role="alert">
        The first name must be between 4 and 20 characters.
      </p>
    );
  }
};

const vlastName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <p className="text-danger" role="alert">
        The last name must be between 3 and 20 characters.
      </p>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <p className="text-danger" role="alert">
        The password must be between 6 and 40 characters.
      </p>
    );
  }
};

const CreateAdmin = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [affilate_phoneNumber, setAffilate_phoneNumber] = useState("");
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeAffilate_phoneNumber = (e) => {
    const affilate_phoneNumber = e;
    setAffilate_phoneNumber(affilate_phoneNumber);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        register(firstName, lastName, email, password, affilate_phoneNumber)
      )
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  useEffect(() => {
    setSuccessful(false);
  }, []);

  return (
    <div className="col-md-12">
      <div className="card card-container mt-0">
        {!successful && (
          <h2 className="text-center color-lime">Add new Admin</h2>
        )}
        {successful && (
          <h4 className="text-center color-lime mb-4">Register successfully</h4>
        )}

        {successful && message && (
          <div className="form-group">
            <div
              className={
                successful ? "alert alert-success" : "alert alert-danger"
              }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="firstname">First Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="firstname"
                  value={firstName}
                  onChange={onChangeFirstName}
                  validations={[required, vfirstName]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastname">Last Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="lastname"
                  value={lastName}
                  onChange={onChangeLastName}
                  validations={[required, vlastName]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group  mt-4 text-center d-grid gap-2 mb-4">
                <button className="btn button-lime button-lime-hover btn-block text-white">
                  Add
                </button>
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default CreateAdmin;
