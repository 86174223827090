import {
  GET_ALLUSER_SUCCESS,
  GET_ALLUSER_FAIL,
  POST_DEACTIVATE_USER_SUCCESS,
  POST_DELETE_USER_SUCCESS,
  POST_DELETE_NOTE_SUCCESS,
  GET_USER_DETAIL_SUCCESS
} from "../actions/type";

const auth_user = localStorage.getItem("user");
if(auth_user){
  var user = auth_user 
}

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export default function usr(state = initialState, action) {
  const { type } = action;
  switch (type) {
   
    case GET_ALLUSER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
      case GET_ALLUSER_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        };
       case POST_DEACTIVATE_USER_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: action.payload,
        };
        case POST_DELETE_USER_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: action.payload,
        };
        case POST_DELETE_NOTE_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: action.payload,
        };
        case GET_USER_DETAIL_SUCCESS:
          return {
            ...state,
            isLoggedIn: true,
            profile: action.payload,
          };
         
    default:
      return state;
  }
}