import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;


const register = (firstName, lastName, email, password,affilate_phoneNumber) => {
    return axios.post(API_URL + "/admin/signup", {
      firstName,
      lastName,
      email,
      password,
      affilate_phoneNumber
    });
  };

  const login = (email, password) => {
    return axios
      .post(API_URL + "/admin/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.data.email) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  };
  
  const logout = () => {
    localStorage.removeItem("user");
    // return axios.post(API_URL + "signout").then((response) => {
    //   return response.data;
    // });
  };
  
  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const getProfile = () => {
    return axios.get(API_URL + "/admin/profile", { headers: authHeader() }).then((response,err) => {
      if (response) {
        console.log(response.data);
        return response;
      }
      return err;
    
    });
  };

  const adminForgotPassword = async(email) => {
    return axios
      .post(API_URL + `/admin/forgotPassword`,{email})
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
  };
  
  const adminResetPassword = async(token,password) => {
    return axios
      .post(API_URL + `/admin/resetPassword/${token}`,{password})
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
  };

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    getProfile,
    adminForgotPassword,
    adminResetPassword,
}

export default AuthService;